




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { formatNumber } from '@/services/functions'

@Component
export default class CharacterStatCard extends Vue {
  @Prop() stat

  formatNumber = formatNumber
}
